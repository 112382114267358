import React, { FunctionComponent } from 'react';
import ListItem from '../../_global/List/ListItem';

interface Props {
  type: string,
  value: string
}

const PersonDetailListItem: FunctionComponent<Props> = ({ type, value }) => (
  <>
    <ListItem>{type}</ListItem>
    <ListItem>{value}</ListItem>
  </>
);

export default PersonDetailListItem;

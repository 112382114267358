import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Films, IFilm, IPeople, ResourcesType,
} from 'swapi-ts';
import ClapperBoard from './clapperboard.svg';
import getIdFromUrl from '../../../helpers/getIdFromUrl/getIdFromUrl';
import hasInIdList from '../../../helpers/hasInIdList';
import useLoading from '../../../hooks/useLoading/useLoading';
import getResourceValues from '../../../helpers/getResourceValues';
import ResourceList from '../../_global/ResourceList/ResourceList';

interface Props {
  person: IPeople
}

const PersonKnownFor: FunctionComponent<Props> = ({ person }) => {
  const [movies, setMovies] = useState<IFilm[]>();
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);
    (async () => {
      const movieIdList = person.films.map(
        (movie) => getIdFromUrl((movie as string), ResourcesType.Films),
      );
      const { resources } = await Films.find(
        (movie) => hasInIdList(movie, ResourcesType.Films, movieIdList),
      );
      setMovies((getResourceValues(resources) as IFilm[]));
      setLoading(false);
    })();
  }, [person]);

  return (
    <>
      {movies && (
        <ResourceList title="Known for" imgUrl={ClapperBoard} items={movies} resourceType={ResourcesType.Films} />
      )}
    </>
  );
};

export default PersonKnownFor;

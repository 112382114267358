import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import SearchInput from './SearchInput/SearchInput';

const Header: FunctionComponent = () => (
  <div className="mb-4 flex flex-col items-center">
    <Link to="/" className="w-52 mb-4">
      <img
        alt="star wars logo"
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Star_Wars_Logo.svg/694px-Star_Wars_Logo.svg.png"
      />
    </Link>
    <SearchInput />
  </div>
);

export default Header;

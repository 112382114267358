import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  IPeople, ISpecie, ResourcesType, Species,
} from 'swapi-ts';
import DarthVader from './darth-vader.svg';
import getIdFromUrl from '../../../helpers/getIdFromUrl/getIdFromUrl';
import hasInIdList from '../../../helpers/hasInIdList';
import useLoading from '../../../hooks/useLoading/useLoading';
import getResourceValues from '../../../helpers/getResourceValues';
import ResourceList from '../../_global/ResourceList/ResourceList';

interface Props {
  person: IPeople
}

const PersonSpecies: FunctionComponent<Props> = ({ person }) => {
  const [species, setSpecies] = useState<ISpecie[]>();
  const { setLoading } = useLoading();

  useEffect(() => {
    setLoading(true);
    (async () => {
      const speciesIdList = person.species.map(
        (currentSpecies) => getIdFromUrl((currentSpecies as string), ResourcesType.Species),
      );
      const { resources } = await Species.find(
        (currentSpecies) => hasInIdList(currentSpecies, ResourcesType.Species, speciesIdList),
      );
      setSpecies((getResourceValues(resources) as ISpecie[]));
      setLoading(false);
    })();
  }, [person]);

  return (
    <>
      {species && (
        <ResourceList title="In species" imgUrl={DarthVader} items={species} resourceType={ResourcesType.Species} />
      )}
    </>
  );
};

export default PersonSpecies;

import React, { FunctionComponent } from 'react';
import {
  IFilm, IPeople, ISpecie, ResourcesType,
} from 'swapi-ts';
import { v4 } from 'uuid';
import ResourceListItem from './ResourceListItem';
import ListTitle from '../List/ListTitle';
import List from '../List/List';

interface Props {
  items: ISpecie[] | IPeople[] | IFilm[],
  resourceType: ResourcesType,
  title: string,
  imgUrl: string
}

const ResourceList: FunctionComponent<Props> = ({
  items, resourceType, title, imgUrl,
}) => (

  <div className="flex flex-col">
    <ListTitle title={title} imgUrl={imgUrl} />
    <List>
      {items && items.map((item) => (
        <ResourceListItem item={item} resourceType={resourceType} key={v4()} />
      ))}
    </List>
  </div>
);

export default ResourceList;

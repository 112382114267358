import React, { FunctionComponent, useEffect, useState } from 'react';
import { ISpecie, ResourcesType, Species } from 'swapi-ts';
import Chewbacca from './chewbacca.svg';
import ResourceList from '../_global/ResourceList/ResourceList';
import useLoading from '../../hooks/useLoading/useLoading';
import getResourceValues from '../../helpers/getResourceValues';
import ListSelectorContext from '../../contexts/ListSelectorContext';
import { SPECIES_LIST, SPECIES_LIST_TITLE } from './selectors';

const SpeciesList: FunctionComponent = () => {
  const { setLoading } = useLoading();
  const [species, setSpecies] = useState<ISpecie[]>([]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const { resources } = await Species.find(() => true);
      setSpecies((getResourceValues(resources) as ISpecie[]));
      setLoading(false);
    })();
  }, []);

  return (
    <ListSelectorContext.Provider value={{
      listSelector: SPECIES_LIST,
      listTitleSelector: SPECIES_LIST_TITLE,
    }}
    >
      <ResourceList
        items={species}
        resourceType={ResourcesType.Species}
        title={ResourcesType.Species}
        imgUrl={Chewbacca}
      />
    </ListSelectorContext.Provider>
  );
};

export default SpeciesList;

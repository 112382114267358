import React, {
  FormEvent, FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import R2D2 from './r2d2-circle.svg';
import useQuery from '../../../../hooks/useQuery';
import { SEARCH_INPUT, SEARCH_SUBMIT_BUTTON } from './selectors';

const SearchInput: FunctionComponent = () => {
  const query = useQuery();
  const [search, setSearch] = useState('');
  const id = useMemo(() => v4(), []);
  const history = useHistory();

  useEffect(() => {
    if (search === '') {
      setSearch(query);
    }
  }, [query]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    history.push(`/search?q=${search}`);
  };

  return (
    <form onSubmit={onSubmit} className="relative">
      <label htmlFor={id} className="text-white font-bold">
        <span className="block">Character search</span>
        <input
          value={search}
          id={id}
          className="bg-banana-yellow pl-2 pr-10 py-2 w-full text-black font-light"
          type="text"
          placeholder="Luke Skywalker"
          data-cy={SEARCH_INPUT}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button type="submit" className="absolute right-1 bottom-1" data-cy={SEARCH_SUBMIT_BUTTON}>
          <img src={R2D2} alt="r2d2" className="w-8" />
        </button>
      </label>
    </form>
  );
};

export default SearchInput;

import React, { FunctionComponent } from 'react';
import LightSaber from './light-saber.svg';
import useLoading from '../../../hooks/useLoading/useLoading';
import { LOADING_SELECTOR } from './selectors';

const Loading: FunctionComponent = () => {
  const { loading } = useLoading();
  return (
    <>
      {loading && (
      <div
        data-cy={LOADING_SELECTOR}
        className={`
          fixed top-0 left-0 w-full h-screen z-10 
          bg-black bg-opacity-90
          flex items-center justify-center
        `}
      >
        <div className="animate-pulse flex items-center flex-col">
          <img src={LightSaber} alt="light saber" className="w-28 mb-4" />
          <div className="text-white font-bold">Loading...</div>
        </div>
      </div>
      )}
    </>
  );
};

export default Loading;

import React, { FunctionComponent } from 'react';
import { ResourcesType } from 'swapi-ts';
import usePeopleInSpecies from '../../hooks/usePeopleInSpecies';
import ResourceList from '../_global/ResourceList/ResourceList';
import DarthVader from './darth-vader.svg';
import useId from '../../hooks/useId';
import ListSelectorContext from '../../contexts/ListSelectorContext';
import { PEOPLE_IN_SPECIES_LIST, PEOPLE_IN_SPECIES_LIST_TITLE } from './selectors';

const PeopleInSpecies: FunctionComponent = () => {
  const specieId = useId();
  const { people, species } = usePeopleInSpecies(specieId);
  const title = `${ResourcesType.People} who are ${species?.name}`;

  return (
    <ListSelectorContext.Provider value={{
      listSelector: PEOPLE_IN_SPECIES_LIST,
      listTitleSelector: PEOPLE_IN_SPECIES_LIST_TITLE,
    }}
    >
      <ResourceList
        items={people}
        resourceType={ResourcesType.People}
        imgUrl={DarthVader}
        title={title}
      />
    </ListSelectorContext.Provider>
  );
};

export default PeopleInSpecies;

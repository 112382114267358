import {
  IFilm, IPeople, IPlanet, ISpecie, IStarship, IVehicle, ResourcesType,
} from 'swapi-ts';
import getIdFromUrl from './getIdFromUrl/getIdFromUrl';

type AnyResourceInterface = IPeople | IFilm | IPlanet | ISpecie | IStarship | IVehicle;

const hasInIdList = (
  currentItem: AnyResourceInterface,
  resourceType: ResourcesType,
  idList: number[],
): boolean => (
  idList.includes(getIdFromUrl(currentItem.url, resourceType))
);

export default hasInIdList;

import React, { FunctionComponent, useContext } from 'react';
import capitalizeFirstLetter from '../../../helpers/capitalizeFirstLetter/capitalizeFirstLetter';
import ListSelectorContext from '../../../contexts/ListSelectorContext';

interface Props {
  title: string,
  imgUrl: string,
  useOriginalTitle?: boolean,
}

const ListTitle: FunctionComponent<Props> = ({ title, imgUrl, useOriginalTitle }) => {
  const { listTitleSelector } = useContext(ListSelectorContext);
  return (
    <div className="flex items-center mb-4">
      <img src={imgUrl} alt="chewbacca" className="w-6 mr-2" />
      <h2 className="text-white font-bold text-2xl" data-cy={listTitleSelector}>
        {useOriginalTitle ? title : capitalizeFirstLetter(title)}
      </h2>
    </div>
  );
};

export default ListTitle;

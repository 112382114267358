import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import { IPeople, People, ResourcesType } from 'swapi-ts';
import useQuery from '../../hooks/useQuery';
import useLoading from '../../hooks/useLoading/useLoading';
import ResourceList from '../_global/ResourceList/ResourceList';
import R2D2 from './r2d2.svg';
import getResourceValues from '../../helpers/getResourceValues';
import { SEARCH_RESULT_LIST, SEARCH_RESULT_LIST_TITLE, SEARCH_RESULT_NO_RESULT } from './selectors';
import ListSelectorContext from '../../contexts/ListSelectorContext';

const SearchResults: FunctionComponent = () => {
  const query = useQuery();
  const { setLoading } = useLoading();
  const [searchResults, setSearchResults] = useState<IPeople[]>([]);

  useEffect(() => {
    setLoading(true);
    const queryArray = query.split(' ');
    if (Array.isArray(queryArray)) {
      (async () => {
        const { resources } = await People.findBySearch(queryArray);
        setSearchResults((getResourceValues(resources) as IPeople[]));
        setLoading(false);
      })();
    }
  }, [query]);

  return (
    <>
      {searchResults && searchResults.length > 0 ? (
        <ListSelectorContext.Provider value={{
          listTitleSelector: SEARCH_RESULT_LIST_TITLE,
          listSelector: SEARCH_RESULT_LIST,
        }}
        >
          <ResourceList
            title={`Search results for '${query}'`}
            resourceType={ResourcesType.People}
            imgUrl={R2D2}
            items={searchResults}
          />
        </ListSelectorContext.Provider>
      ) : (
        <div className="text-white text-center" data-cy={SEARCH_RESULT_NO_RESULT}>No result found!</div>
      )}
    </>
  );
};

export default SearchResults;

import React, { FunctionComponent } from 'react';
import {
  IFilm, IPeople, ISpecie, ResourcesType,
} from 'swapi-ts';
import { Link } from 'react-router-dom';
import getIdFromUrl from '../../../helpers/getIdFromUrl/getIdFromUrl';
import ListItem from '../List/ListItem';

interface Props {
  item: ISpecie | IPeople | IFilm,
  resourceType: ResourcesType
}

const ResourceListItem: FunctionComponent<Props> = ({ item, resourceType }) => {
  const isFilm = 'title' in item;

  return (
    <>
      {isFilm ? (
        <ListItem>
          {(item as IFilm).title}
        </ListItem>
      ) : (
        <Link to={`/${resourceType}/${getIdFromUrl(item.url, resourceType)}`}>
          <ListItem>
            {'name' in item && item.name}
          </ListItem>
        </Link>
      )}
    </>
  );
};

export default ResourceListItem;

import React, { FunctionComponent } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import Header from './Header/Header';
import PeopleInSpecies from '../PeopleInSpecies/PeopleInSpecies';
import Loading from '../_global/Loading/Loading';
import SpeciesList from '../SpeciesList/SpeciesList';
import SearchResults from '../SearchResults/SearchResults';
import Person from '../Person/Person';

const App: FunctionComponent = () => (
  <BrowserRouter>
    <Loading />
    <div className="flex flex-col justify-center mx-auto p-4 max-w-screen-md">
      <Header />
      <Switch>
        <Route path="/people/:id">
          <Person />
        </Route>
        <Route path="/species/:id">
          <PeopleInSpecies />
        </Route>
        <Route path="/search">
          <SearchResults />
        </Route>
        <Route path="/">
          <SpeciesList />
        </Route>
      </Switch>
    </div>
  </BrowserRouter>
);

export default App;

import { ResourcesType } from 'swapi-ts';

const getIdFromUrl = (url: string, category: ResourcesType): number => {
  if (url && category) {
    const expectedId = +(
      url
        .replace(`https://swapi.dev/api/${category}`, '')
        .replace(/\//g, '')
    );
    if (expectedId && Number.isInteger(expectedId)) {
      return expectedId;
    }
    return -1;
  }
  return -1;
};

export default getIdFromUrl;

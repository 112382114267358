import React, { FunctionComponent, useContext } from 'react';
import ListSelectorContext from '../../../contexts/ListSelectorContext';

const List: FunctionComponent = ({ children }) => {
  const { listSelector } = useContext(ListSelectorContext);
  return (
    <ul className="grid grid-cols-2 gap-4 mb-4" data-cy={listSelector}>{children}</ul>
  );
};

export default List;

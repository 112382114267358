import { useEffect, useState } from 'react';
import {
  IPeople, ISpecie, People, ResourcesType, Species,
} from 'swapi-ts';
import hasInIdList from '../helpers/hasInIdList';
import getIdFromUrl from '../helpers/getIdFromUrl/getIdFromUrl';
import useLoading from './useLoading/useLoading';
import getFirstFromResources from '../helpers/getFirstFromResources';

type UsePeopleResponse = {
  people: IPeople[],
  species: ISpecie | null
};

const fetchSpeciesById = async (id: number): Promise<ISpecie | null> => {
  const { resources } = await Species
    .find((species) => hasInIdList(species, ResourcesType.Species, [id]));

  return <ISpecie>getFirstFromResources(resources);
};

const fetchPeopleIdListForSpecies = async (species: ISpecie): Promise<number[]> => (
  species.people.map((personUrl) => {
    if (typeof personUrl === 'string') {
      return getIdFromUrl(personUrl, ResourcesType.People);
    }
    return -1;
  }).filter((personId) => personId > -1)
);

const fetchPeopleByIdList = async (idList: number[]) => {
  const { resources } = await People
    .find((person) => hasInIdList(person, ResourcesType.People, idList));
  return resources;
};

const usePeopleInSpecies = (specieId: number): UsePeopleResponse => {
  const { setLoading } = useLoading();
  const [people, setPeople] = useState<IPeople[]>([]);
  const [species, setSpecies] = useState<ISpecie | null>(null);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const currentSpecies = await fetchSpeciesById(specieId);
      if (currentSpecies) {
        const peopleIdList = await fetchPeopleIdListForSpecies(currentSpecies);
        const peopleResources = await fetchPeopleByIdList(peopleIdList);
        setSpecies(currentSpecies);
        setPeople(peopleResources.map((person) => person.value));
        setLoading(false);
      }
    })();
  }, []);

  return {
    people,
    species,
  };
};

export default usePeopleInSpecies;

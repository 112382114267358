import React, { FunctionComponent } from 'react';
import { IPeople } from 'swapi-ts';
import List from '../../_global/List/List';
import PersonDetailListItem from './PersonDetailListItem';

interface Props {
  person: IPeople
}

const PersonDetailList: FunctionComponent<Props> = ({ person }) => (
  <List>
    <PersonDetailListItem type="Name" value={person.name} />
    <PersonDetailListItem type="Birth year" value={person.birth_year} />
    <PersonDetailListItem type="Gender" value={person.gender} />
    <PersonDetailListItem type="Eye color" value={person.eye_color} />
    <PersonDetailListItem type="Hair color" value={person.hair_color} />
    <PersonDetailListItem type="Skin color" value={person.skin_color} />
    <PersonDetailListItem type="Height" value={person.height} />
    <PersonDetailListItem type="Mass" value={person.mass} />
  </List>
);

export default PersonDetailList;

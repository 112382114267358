import { useEffect, useState } from 'react';
import LoadingService from './LoadingService';

type UseSearchResponseType = {
  loading: boolean,
  setLoading: (value: boolean) => void,
};

const useLoading = (): UseSearchResponseType => {
  const [domLoading, setDomLoading] = useState(LoadingService.getLoading());

  const setLoading = (loading: boolean) => LoadingService.setLoading(loading);

  useEffect(() => {
    LoadingService.getLoadingObservable().subscribe((loading) => {
      setDomLoading(loading);
    });
  }, []);

  return {
    loading: domLoading,
    setLoading,
  };
};

export default useLoading;

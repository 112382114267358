import { IFilm, IPeople, ISpecie } from 'swapi-ts';
import { Resource } from '../types';

const getFirstFromResources = (resources: Resource[]): ISpecie | IPeople | IFilm | null => {
  if (resources.length > 0 && resources[0].value) {
    return resources[0].value;
  }
  return null;
};

export default getFirstFromResources;

import React, { FunctionComponent, useEffect, useState } from 'react';
import { IPeople, People, ResourcesType } from 'swapi-ts';
import ListTitle from '../_global/List/ListTitle';
import LightSaber from './lightsaber.svg';
import useId from '../../hooks/useId';
import useLoading from '../../hooks/useLoading/useLoading';
import hasInIdList from '../../helpers/hasInIdList';
import getFirstFromResources from '../../helpers/getFirstFromResources';
import PersonDetailList from './PersonDetailList/PersonDetailList';
import PersonKnownFor from './PersonKnownFor/PersonKnownFor';
import PersonSpecies from './PersonSpecies/PersonSpecies';
import ListSelectorContext from '../../contexts/ListSelectorContext';
import {
  PERSON_DETAILS, PERSON_MOVIES, PERSON_SPECIES, PERSON_TITLE,
} from './selectors';

const Person: FunctionComponent = () => {
  const { setLoading } = useLoading();
  const [person, setPerson] = useState<IPeople | null>(null);
  const id = useId();

  useEffect(() => {
    setLoading(true);
    (async () => {
      const { resources } = await People.find(
        (currentPerson) => hasInIdList(currentPerson, ResourcesType.People, [id]),
      );
      setPerson(getFirstFromResources(resources) as IPeople);
      setLoading(false);
    })();
  }, [id]);

  return (
    <div>
      {person && (
        <>
          <ListSelectorContext.Provider value={{ listTitleSelector: PERSON_TITLE, listSelector: '' }}>
            <ListTitle title={person.name} imgUrl={LightSaber} useOriginalTitle />
          </ListSelectorContext.Provider>
          <ListSelectorContext.Provider value={{ listTitleSelector: '', listSelector: PERSON_DETAILS }}>
            <PersonDetailList person={person} />
          </ListSelectorContext.Provider>
          <ListSelectorContext.Provider value={{ listTitleSelector: '', listSelector: PERSON_MOVIES }}>
            <PersonKnownFor person={person} />
          </ListSelectorContext.Provider>
          <ListSelectorContext.Provider value={{ listTitleSelector: '', listSelector: PERSON_SPECIES }}>
            <PersonSpecies person={person} />
          </ListSelectorContext.Provider>
        </>
      )}
    </div>
  );
};

export default Person;

import { BehaviorSubject, Observable } from 'rxjs';

const loading = new BehaviorSubject(false);

const LoadingService = {
  setLoading: (newLoading: boolean): void => loading.next(newLoading),
  getLoading: (): boolean => loading.getValue(),
  getLoadingObservable: (): Observable<boolean> => loading.asObservable(),
};

export default LoadingService;
